import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Chainable Components",
  "subtitle": "A composable API for reusable React components",
  "date": "2018-09-04T00:00:00.000Z",
  "layout": "post",
  "draft": false,
  "tags": ["javascript", "higher order components", "render props", "react"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The easiest way to write `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Don%27t_repeat_yourself"
      }}>{`DRY`}</a>{` reusable React components is with Render Props. However, they aren't inherently succinct or easy combine. HOCs are an equivalent way to write DRY React components, and offer more composability, but `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=BcVAq3YFiuc"
      }}>{`leave a lot to be desired`}</a>{`. Fortunately, we can realize a more composable and succinct API for reusable React components.`}</p>
    <h2>{`The Essence of reusable React components`}</h2>
    <p>{`The essence of HOCs and Render Props is to provide a value, and abstract the process of producing and maintaining that value. Take this `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`js±WithAuthentication`}</code>{` render prop for example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`WithAuthentication`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`user`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`You are logged `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`WithAuthentication`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`WithAuthentication`}</code>{` will either produce the current user that is logged in, or render a login screen. This render prop can be used anywhere an application needs to define a view that should only be visible to logged in users. In a sense, it abstracts away the logic that's used to produce a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`user`}</code>{` value, and it "wraps" it in a render prop container. In order to access this value, we provide a callback which takes this value as a parameter. Using multiple Render Props together can get verbose and clumsy, leading to heavily nested code.`}</p>
    <p>{`Here's another example of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withState`}</code>{` HOC from `}<a parentName="p" {...{
        "href": "https://github.com/acdlite/recompose"
      }}>{`recompose`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`withState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'counter'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'setCounter'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` counter`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` setCounter `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    Count`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`counter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onClick`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`setCounter`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`n`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` n `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`+`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Increment`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onClick`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`setCounter`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`n`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` n `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`-`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Decrement`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`withState`}</code>{` provides the value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{counter, setCounter}`}</code>{`, and abstracts the way that state is initialized and maintained. In order to access this value, we provide a component which takes this value as props. HOCs can be succinctly composed with a common `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`compose`}</code>{` function, but this often leads to trouble when multiple HOCs use the same name for their props.`}</p>
    <p>{`It would be optimal if we could find a way to combine the succinctness of HOCs with the flexibility of Render Props!`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/pfgray/chainable-components"
      }}>{`Chainable Components`}</a>{` is an attempt to fill this need, using advanced functional programming techniques.`}</p>
    <p>{`Here's me talking to the React DC meetup group about this library.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`youtube: upUp34fVnvY`}</code></p>
    <h2>{`A Chainable API`}</h2>
    <p>{`Just like Render Props and HOCs, a chainable component abstracts over the logic to produce a value that can be used to render React views. The easiest way to create a chainable component is to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`of`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` five `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` ChainableComponent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'five'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`five`}</code>{` is now a chainable component which contains the string value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"five"`}</code>{`. You can think of it as just a wrapper for that value. In order to use that value, we need to supply a view for that value:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`five`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`num`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Your number is`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`num`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre>{`
      `}</div>
    <p>{`In addition to wrapping static values, we can also convert Render Props and HOCs to chainable components. Here's an example using the Context API from React:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Consumer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Provider `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`createContext`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Default Value"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Consumer is a Render Prop!`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` withConsumer `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fromRenderProp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Consumer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`MyComponent`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`withConsumer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`value`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Provider`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Overriden`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`withConsumer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`value`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Provider`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre>{`
      `}</div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withConsumer`}</code>{` chainable's value is the same value that would have been supplied to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Consumer`}</code>{`'s children prop. Instead of:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Consumer`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`value`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Consumer`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre>{`
      `}</div>
    <p>{`we have:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`withConsumer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`value`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`span`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`span`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`These seem quite similar, so why convert a render prop to a chainable component in the first place? The usefulness of chainable components, is that we don't need to provide the view right away. We can store it in a variable, return it from a function, transform the value inside or even `}<em parentName="p">{`compose`}</em>{` them with other chainables!`}</p>
    <h2>{`map`}</h2>
    <p>{`Map allows us to transform the value inside of a chainable:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` five `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` ChainableComponent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'five'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` five`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`str`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` str`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`length`}</code>{` is now a chainable component containing the number value, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`4`}</code>{`.`}</p>
    <h2>{`chain`}</h2>
    <p>{`We can compose chainable components with other chainables via the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{` method.`}</p>
    <p>{`Let's say we have a chainable component called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withState`}</code>{`, which is equivalent to recompose's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withState`}</code>{`, but converted to a chainable component.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` withTwoCounters `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`chain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`outer`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`inner`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      inner`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      outer
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`withTwoCounters`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`inner`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` outer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Outer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`outer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`button onClick`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` outer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`update`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`outer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Inner`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`inner`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`button onClick`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` inner`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`update`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`inner`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`button`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre>{`
      `}</div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{` method is quite similar to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{`, but instead of returning just any value, it returns another chainable component. The difference being that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{` doesn't simply return a chainable component of a chainable component, but it `}<em parentName="p">{`composes`}</em>{` both of the chainables together!`}</p>
    <h2>{`Do`}</h2>
    <p>{`If you've noticed, our chained anonymous functions are `}<em parentName="p">{`still`}</em>{` subject to nesting for every "link" in the chain (although it's much better than with Render Props). Unfortunately, there's nothing that the language affords us to solve this problem (This was also true with Promises, until `}<a parentName="p" {...{
        "href": "https://gist.github.com/MaiaVictor/bc0c02b6d1fbc7e3dbae838fb1376c80"
      }}>{`aync/await`}</a>{`).`}</p>
    <p>{`The static `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` method can help alleviate this nesting.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` withThreeCounters `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  ChainableComponent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Do`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`outer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` outer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`middle`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` outer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`middle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` outer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`inner`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` middle`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` outer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`outer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` inner`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` middle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

withThreeCounters`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`outer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` inner`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` middle`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Outer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`outer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onClick`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` outer`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`update`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`outer`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`value `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`+`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Middle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`middle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onClick`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` middle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`update`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`middle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`value `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`+`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Inner`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`inner`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`onClick`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` inner`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`update`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`inner`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`value `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`+`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` takes care of calling the necessary `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{` methods, keeping track of the contextual values to pass to our anonymous functions. The last function is passed to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map`}</code>{`, and whatever value is returned will be the ultimate value inside the final chainable.`}</p>
    <p>{`If you feel that this API seems oddly familiar, that's because it should. This pattern appears often during functional programming, and it's called the `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=9QveBbn7t_c"
      }}><em parentName="a">{`monad`}</em></a>{` pattern. Sometimes the "chain" function is called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`then`}</code>{` (for promises), and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flatMap`}</code>{` (for arrays), but it's still the same pattern. We chose the name `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{` to be compatible with the `}<a parentName="p" {...{
        "href": "https://github.com/fantasyland/fantasy-land#monad"
      }}>{`fantasy-land`}</a>{` specification for functional programming structures. This means that chainable components are compatible with all fantasy-land libraries (most notably, `}<a parentName="p" {...{
        "href": "https://ramdajs.com/"
      }}>{`Ramda`}</a>{`).`}</p>
    <p>{`Utilizing advanced functional patterns gives us some extra composability over plain Render Props and HOCs. In your next project, consider giving `}<a parentName="p" {...{
        "href": "https://github.com/pfgray/chainable-components"
      }}>{`Chainable Components`}</a>{` a whirl when writing reusable React code.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      